import React from 'react';
import { AreaChart, XAxis, YAxis, Tooltip, Legend, ReferenceLine, Area, ResponsiveContainer } from 'recharts';
import CustomTooltip from './CustomTooltip';

const AirQualityGraph = ({ data }) => {
    return (
        console.log(data),
        <ResponsiveContainer width='100%' aspect={4.0 / 1.0}>
            <AreaChart
                data={data}
                syncId="anyId">
                <XAxis dataKey="date" />\
                <YAxis />
                <Legend />
                <Tooltip content={<CustomTooltip />} />
                <ReferenceLine y="1000" stroke="#2a9d8f" label="Alarm" />
                <Area name="Air Quality (%)" type="monotone" dataKey="gas_analog" stroke="#2a9d8f" fill="#2a9d8f" unit="%" />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default AirQualityGraph;
