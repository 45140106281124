import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const StyledGridItem = styled(Grid)(({ theme }) => ({
    borderTopWidth: 4,
    borderTopStyle: 'solid',
    '&.low': {
        borderTopColor: theme.palette.primary.main,
    },
    '&.medium': {
        borderTopColor: theme.palette.secondary.light,
    },
    '&.high': {
        borderTopColor: theme.palette.secondary.dark,
    },
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    '&:last-child': {
        marginBottom: 0,
    },
}));

const priorityOptions = [
    { value: '3', label: 'high' },
    { value: '2', label: 'medium' },
    { value: '1', label: 'low' }
];

const formatPriority = (row) => {
    const priority = priorityOptions.find(option => option.value == row.priority);
    return priority ? priority.label : 'Unknown';
};

const TasksTable = ({ tasks, onDelete, onUpdate, onTagClick, onPostpone }) => {
    const navigate = useNavigate();

    const formatStatus = (row) => {
        if (row.is_completed) {
            return (
                <CheckCircleIcon
                    fontSize="small"
                    titleAccess="Status"
                    style={{ color: '#5e151e' }}
                    onClick={() => handleUndoneTask(row)}
                    sx={{
                        '&:hover': {
                            cursor: 'pointer'
                        },
                    }}
                />
            );
        } else {
            return (
                <CheckCircleIcon
                    fontSize="small"
                    titleAccess="Status"
                    style={{ color: 'white' }}
                    onClick={() => handleDoneTask(row)}
                    sx={{
                        '&:hover': {
                            cursor: 'pointer'
                        },
                    }}
                />
            );
        }
    };

    const handleUndoneTask = (row) => {
        row.is_completed = false;
        onUpdate(row);
    };

    const handleDoneTask = (row) => {
        row.is_completed = true;
        onUpdate(row);
    };

    const handleTagClick = (tag) => {
        onTagClick(tag);
    };

    const handleLinkTask = (row) => {
        navigate(`/tasks/${row.id}`);
    };

    return (
        <Grid container columnSpacing={4}>
            {tasks.map((row, index) => (
                <StyledGridItem key={index} item xs={12} sm={6} md={4} className={formatPriority(row)}>
                    <Typography variant="h5" mb={2}>{row.title}</Typography>
                    <Typography variant="body1" >{row.description}</Typography>
                    {row.tags.length > 0 && (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            marginTop: '24px',
                        }}>
                            {row.tags.split(',').map(item => item.trim()).map((value, index) => (
                                <Chip
                                    key={index}
                                    label={value}
                                    variant="outlined"
                                    style={{ marginRight: '8px', marginBottom: '4px' }}
                                    onClick={() => handleTagClick(value)}
                                />
                            ))}
                        </div>
                    )}
                    <Typography variant="body2" mt={4}>Due Date: {row.due_date}</Typography>
                    <Stack direction="row" spacing={1} mt={4}>
                        <EditNoteIcon
                            fontSize="small"
                            titleAccess="Edit"
                            style={{ color: 'white' }}
                            onClick={() => handleLinkTask(row)}
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer'
                                },
                            }}
                        />
                        {formatStatus(row)}
                        <DeleteIcon
                            fontSize="small"
                            titleAccess="Delete"
                            style={{ color: 'white' }}
                            onClick={() => onDelete(row)}
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer'
                                },
                            }}
                        />
                        <PendingActionsIcon
                            fontSize="small"
                            titleAccess="Postpone (+90 days)"
                            style={{ color: 'white' }}
                            onClick={() => onPostpone(row)}
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer'
                                },
                            }}
                        />
                        {row.link && (
                            <OpenInNewIcon
                                fontSize="small"
                                titleAccess="Open Link"
                                style={{ color: 'white' }}
                                onClick={() => window.open(row.link, '_blank')}
                            />
                        )}
                    </Stack>
                </StyledGridItem>
            ))}
        </Grid>
    );
};

export default TasksTable;
