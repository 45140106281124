import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import CustomTooltip from './CustomTooltip';

const WindGraph = ({ data }) => {
    return (
        <ResponsiveContainer width='100%' aspect={4.0 / 1.0}>
            <BarChart
                data={data}
                syncId="anyId">
                <Legend />
                <Tooltip content={<CustomTooltip />} />
                <XAxis dataKey="date" />
                <YAxis scale="log" domain={[1, 250]} />
                <Bar name="Wind" type="monotone" dataKey="wind_speed" stroke="#06d6a0" fill="#06d6a0" unit="m/s" />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default WindGraph;
